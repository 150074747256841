import { Grid } from '@mantine/core';
import { Title } from '@mantine/core';
import { Image } from '@mantine/core';
import { Text } from '@mantine/core';
import { Center } from '@mantine/core';
import { Badge } from '@mantine/core';
import { Group, Button } from '@mantine/core';
import { Card } from '@mantine/core';
import { Highlight, Timeline } from '@mantine/core';
import { IconGitBranch, IconGitPullRequest, IconGitCommit, IconMessageDots } from '@tabler/icons';
import React, { useState } from 'react';
import { Divider } from '@mantine/core';
import { Helmet } from 'react-helmet';

import Pavlichenko from "./assets/Pavlichenko.jpg";
import blog1 from "./assets/blog1.gif";
import blog2 from "./assets/blog2.webp";
import crowd_kit from "./assets/crowd_kit.png";
import logo_icwe_draft from "./assets/logo_icwe_draft.png";
import NeurIPS_logo from "./assets/NeurIPS_logo.png";
import sgcn from "./assets/sgcn.png";
import VLDB from "./assets/VLDB.jpeg";
import SIGIR23 from "./assets/SIGIR23.png";

function App() {
  const [emailVariant, setEmailVariant] = useState("outline");
  const [cvVariant, setCvVariant] = useState("outline");
  const [bioVariant, setBioVariant] = useState("outline");
  const [scholarVariant, setScholarVariant] = useState("outline");
  const [twitterVariant, setTwitterVariant] = useState("outline");
  const [githubVariant, setGithubVariant] = useState("outline");
  return (
    <div className="App">
    <Helmet>
          <title>Nikita Pavlichenko</title>
    </Helmet>
    <Grid grow style={{marginTop: 24}}>
      <Grid.Col span={1}></Grid.Col>
      <Grid.Col span={6}>
        <Grid grow>
          <Grid.Col span={4}>
            <Center>
            <Title order={1}>Nikita Pavlichenko</Title>
            </Center>
            <Text style={{marginTop: 20}}>
              I am a research scientist at <Text variant="link" component="a" href="https://toloka.ai/research">Toloka Research</Text>, where I work on connecting humans and AI. Particularly, my research is focused on building models that require human insights, such as RLHF, or using advances in machine learning to improve data collection techniques.
            </Text>
            <br />
            <Text>
             I earned my Master's degree at the Moscow Institute of Science and Technology. Then, I was lucky to get both academic and industrial experience in most of the major AI fields: NLP, CV, and RL. However, I'm really passionate about geometric deep learning; check out my <Text variant="link" component="a" href="https://iopscience.iop.org/article/10.1088/2632-2153/abf856">S-GCN paper</Text>. At Toloka, I also implemented various truth-inference methods for crowdsourcing in the open-source Python library <Text variant="link" component="a" href="https://github.com/Toloka/crowd-kit">Crowd-Kit</Text>.</Text>
          </Grid.Col>
          <Grid.Col span={4}>
            <Center>
            <Image
              radius={150}
              width={300}
              src={Pavlichenko}
              alt="It's me"
            />
            </Center>
          </Grid.Col>
        </Grid>

        <Center>
          <Group style={{marginTop: 20}}>
            <Badge component="a" href="mailto:nikita.v.pavlichenko@gmail.com" variant={emailVariant} onMouseEnter={() => setEmailVariant("filled")}
        onMouseLeave={() => setEmailVariant("outline")}>
              Email
            </Badge>
            {/* <Badge component="a" href="https://pavlichenko.info/static/data/NikitaPavlichenkoCV.pdf" variant={cvVariant} onMouseEnter={() => setCvVariant("filled")}
        onMouseLeave={() => setCvVariant("outline")}>
              CV
            </Badge>
            <Badge component="a" href="https://pavlichenko.info/static/data/NikitaPavlichenkoBio.txt" variant={bioVariant} onMouseEnter={() => setBioVariant("filled")}
        onMouseLeave={() => setBioVariant("outline")}>
              Bio
            </Badge> */}
            <Badge component="a" href="https://scholar.google.com/citations?user=2mSLYhcAAAAJ&hl=en" variant={scholarVariant} onMouseEnter={() => setScholarVariant("filled")}
        onMouseLeave={() => setScholarVariant("outline")}>
              Google Scholar
            </Badge>
            <Badge component="a" href="http://twitter.com/nv_pavlichenko" variant={twitterVariant} onMouseEnter={() => setTwitterVariant("filled")}
        onMouseLeave={() => setTwitterVariant("outline")}>
              Twitter
            </Badge>
            <Badge component="a" href="https://github.com/pilot7747/" variant={githubVariant} onMouseEnter={() => setGithubVariant("filled")}
        onMouseLeave={() => setGithubVariant("outline")}>
              GitHub
            </Badge>
          </Group>
        </Center>
        <Title order={2} style={{marginTop: 20}}>Research</Title>

        <Highlight highlight={['highlighted']} style={{marginTop: 20}}>
        I'm interested in (deep) machine learning, crowdsourcing, graph machine learning, and natural language processing. Much of my research is on either how to use smart ML algorithms to improve crowdsourcing or how to improve ML algorithms using human insight. Representative papers are highlighted.</Highlight>

        <Card shadow="sm" p="lg" radius="md" style={{marginTop: 20, backgroundColor: "#FFEC99"}} withBorder>
          <Grid grow>
            <Grid.Col span={1}>
              <Image
                src={SIGIR23}
              />
            </Grid.Col>
            <Grid.Col span={8}>
              <Title order={4} variant="link" component="a" href="https://dl.acm.org/doi/abs/10.1145/3539618.3592000">Best Prompts for Text-to-Image Models and How to Find Them</Title>
              <Text>
                <Text component='span' weight={700}>Nikita Pavlichenko</Text>, <Text variant="link" component="a" href="http://ustalov.com/">Dmitry Ustalov</Text> 
              </Text>
              <Text>
                SIGIR, 2023 
              </Text>
              <Text variant='link' component='a' href='http://github.com/Toloka/BestPrompts'>GitHub page</Text>
              <br />
              <br />
              <Text>
                Method for finding the most aesthetically appealing prompts for text-to-image models using human feedback.
              </Text>
            </Grid.Col>
          </Grid>
        </Card>

        <Card shadow="sm" p="lg" radius="md" style={{marginTop: 20}} withBorder>
          <Grid grow>
            <Grid.Col span={1}>
              <Image
                src={logo_icwe_draft}
              />
            </Grid.Col>
            <Grid.Col span={8}>
              <Title order={4} variant="link" component="a" href="https://link.springer.com/chapter/10.1007/978-3-031-09917-5_45">Web Engineering with Human-in-the-Loop</Title>
              <Text>
                <Text variant="link" component="a" href="http://ustalov.com/">Dmitry Ustalov</Text>, <Text component='span' weight={700}>Nikita Pavlichenko</Text>, <Text variant="link" component="a" href="https://linkedin.com/in/btseytlin">Boris Tseytlin</Text>, <Text variant="link" component="a" href="https://www.linkedin.com/in/dbaidakova/">Daria Baidakova</Text>, <Text variant="link" component="a" href="https://scholar.google.com/citations?user=TdwHd_oAAAAJ&hl=en">Alexey Drutsa</Text>
              </Text>
              <Text>
                ICWE, 2022 
              </Text>
              <Text variant="link" component="a" href="https://toloka.ai/events/icwe-2022/">Tutorial page</Text>
              <br />
              <br />
              <Text>
                Tutorial on how to use crowdsourcing for search engine relevance assesment.
              </Text>
            </Grid.Col>
          </Grid>
        </Card>

        <Card shadow="sm" p="lg" radius="md" style={{marginTop: 20, backgroundColor: "#FFEC99"}} withBorder>
          <Grid grow>
            <Grid.Col span={1}>
              <Image
                src={NeurIPS_logo}
              />
            </Grid.Col>
            <Grid.Col span={8}>
              <Title order={4} variant="link" component="a" href="https://datasets-benchmarks-proceedings.neurips.cc/paper/2021/hash/65ded5353c5ee48d0b7d48c591b8f430-Abstract-round1.html">CrowdSpeech and Vox DIY: Benchmark Dataset for Crowdsourced Audio Transcription</Title>
              <Text>
                <Text component='span' weight={700}>Nikita Pavlichenko</Text>, <Text variant="link" component="a" href="https://www.cs.cmu.edu/~istelmak/">Ivan Stelmakh</Text>, <Text variant="link" component="a" href="http://ustalov.com/">Dmitry Ustalov</Text> 
              </Text>
              <Text>
                NeurIPS Track on Datasets and Benchmarks, 2021 
              </Text>
              <Text variant='link' component='a' href='https://github.com/Toloka/CrowdSpeech'>GitHub page</Text>
              <br />
              <br />
              <Text>
                State-of-the-art crowdsourced text aggregation method and a large-scale dataset of crowdsourced audio transcriptions.
              </Text>
            </Grid.Col>
          </Grid>
        </Card>

        <Card shadow="sm" p="lg" radius="md" style={{marginTop: 20}} withBorder>
          <Grid grow>
            <Grid.Col span={1}>
              <Image
                src={NeurIPS_logo}
              />
            </Grid.Col>
            <Grid.Col span={8}>
              <Title order={4} variant="link" component="a" href="https://arxiv.org/pdf/2110.14990">IMDB-WIKI-SbS: An Evaluation Dataset for Crowdsourced Pairwise Comparisons </Title>
              <Text>
                <Text component='span' weight={700}>Nikita Pavlichenko</Text>, <Text variant="link" component="a" href="http://ustalov.com/">Dmitry Ustalov</Text>
              </Text>
              <Text>
                NeurIPS Data-Centric AI Workshop, 2021
              </Text>
              <Text variant='link' component='a' href='https://github.com/Toloka/IMDB-WIKI-SbS'>GitHub page</Text>
              <br />
              <br />
              <Text>
                Largest open-source dataset of crowdsourced pairwise comparisons.
              </Text>
            </Grid.Col>
          </Grid>
        </Card>

        <Card shadow="sm" p="lg" radius="md" style={{marginTop: 20}} withBorder>
          <Grid grow>
            <Grid.Col span={1}>
              <Image
                src={crowd_kit}
              />
            </Grid.Col>
            <Grid.Col span={8}>
              <Title order={4} variant="link" component="a" href="https://arxiv.org/pdf/2109.08584">A General-Purpose Crowdsourcing Computational Quality Control Toolkit for Python </Title>
              <Text>
                <Text variant="link" component="a" href="http://ustalov.com/">Dmitry Ustalov</Text>, <Text component='span' weight={700}>Nikita Pavlichenko</Text>, Vladimir Losev, Iulian Giliazev, Evgeny Tulin 
              </Text>
              <Text>
                HCOMP, 2021
              </Text>
              <Text><Text variant='link' component='a' href='https://github.com/Toloka/crowd-kit'>GitHub page</Text>, <Text variant='link' component='a' href='https://toloka.ai/en/docs/crowd-kit/'>Docs</Text></Text>
              <br />
              <Text>
                Crowd-Kit is a powerful Python library that implements commonly-used aggregation methods for crowdsourced annotation and offers the relevant metrics and datasets.
              </Text>
            </Grid.Col>
          </Grid>
        </Card>

        <Card shadow="sm" p="lg" radius="md" style={{marginTop: 20}} withBorder>
          <Grid grow>
            <Grid.Col span={1}>
              <Image
                src={VLDB}
              />
            </Grid.Col>
            <Grid.Col span={8}>
              <Title order={4} variant="link" component="a" href="http://ceur-ws.org/Vol-2932/invited1.pdf?version=1">VLDB 2021 Crowd Science Challenge on Aggregating Crowdsourced Audio Transcriptions </Title>
              <Text>
                <Text variant="link" component="a" href="http://ustalov.com/">Dmitry Ustalov</Text>, <Text component='span' weight={700}>Nikita Pavlichenko</Text>, <Text variant="link" component="a" href="https://www.cs.cmu.edu/~istelmak/">Ivan Stelmakh</Text>, Dmitry Kuznetsov 
              </Text>
              <Text>
                VLDB, 2021
              </Text>
              <Text><Text variant='link' component='a' href='https://crowdscience.ai/challenges/vldb21'>Competition page</Text></Text>
              <br />
              <Text>
                Shared task competition on text aggregation.
              </Text>
            </Grid.Col>
          </Grid>
        </Card>

        <Card shadow="sm" p="lg" radius="md" style={{marginTop: 20, backgroundColor: "#FFEC99"}} withBorder>
          <Grid grow>
            <Grid.Col span={1}>
              <Image
                src={sgcn}
              />
            </Grid.Col>
            <Grid.Col span={8}>
              <Title order={4} variant="link" component="a" href="https://iopscience.iop.org/article/10.1088/2632-2153/abf856">Spherical convolutions on molecular graphs for protein model quality assessment </Title>
              <Text>
                <Text variant="link" component="a" href="https://igashov.github.io/">Ilia Igashov</Text>, <Text component='span' weight={700}>Nikita Pavlichenko</Text>, <Text variant="link" component="a" href="https://team.inria.fr/nano-d/team-members/sergei-grudinin/">Sergei Grudinin</Text>
              </Text>
              <Text>
                Machine Learning: Science and Technology, 2021
              </Text>
              <Text variant='link' component='a' href='https://gitlab.inria.fr/GruLab/s-gcn'>Source code</Text>
              <br />
              <br />
              <Text>
                State-of-the-art protein model quality assesment method based on a novel type of graph convolution operation.
              </Text>
            </Grid.Col>
          </Grid>
        </Card>

        <Title order={2} style={{marginTop: 40}}>Experience</Title>

        <Timeline bulletSize={24} lineWidth={2} style={{marginTop: 20}}>
          <Timeline.Item bullet={<Image src='https://tlkfrontprod.azureedge.net/portal-production/static/images/favicon-new/apple-icon-180x180.png'></Image>} title="Toloka AI">
            <Text>Research Scientist</Text>
            <Text size="xs" mt={4}>2020 - Now</Text>
          </Timeline.Item>

          <Timeline.Item bullet={<Image src='https://mipt.ru/favicon.ico'></Image>} title="Moscow Institute of Physics and Technology">
            <Text>Research Intern</Text>
            <Text size="xs" mt={4}>2019 - 2020</Text>
          </Timeline.Item>

          <Timeline.Item title="Yandex Video" bullet={<Image src='https://www.shareicon.net/download/2016/07/08/116986_email.ico' />}>
            <Text>Machine Learning Engineer Intern</Text>
            <Text size="xs" mt={4}>2019 - 2019</Text>
          </Timeline.Item>

          <Timeline.Item title="Yandex Search" bullet={<Image src='https://www.shareicon.net/download/2016/07/08/116986_email.ico' />}>
            <Text>Software Engineering Intern</Text>
            <Text size="xs" mt={4}>2018 - 2018</Text>
          </Timeline.Item>
        </Timeline>

        <Title order={2} style={{marginTop: 40}}>Blog</Title>

        <Card shadow="sm" p="lg" radius="md" style={{marginTop: 20}} withBorder>
          <Grid grow>
            <Grid.Col span={1}>
              <Image
                src={blog2}
              />
            </Grid.Col>
            <Grid.Col span={8}>
              <Title order={4} variant="link" component="a" href="https://pub.towardsai.net/improving-text-to-image-models-by-prompt-engineering-with-rlhf-71f28177f4cb">Improving Text-to-Image Models by Prompt Engineering with RLHF</Title>
              <Text>
                How to build a model that rewrites user prompts through RLHF.
              </Text>
            </Grid.Col>
          </Grid>
        </Card>

        <Card shadow="sm" p="lg" radius="md" style={{marginTop: 20}} withBorder>
          <Grid grow>
            <Grid.Col span={1}>
              <Image
                src={blog1}
              />
            </Grid.Col>
            <Grid.Col span={8}>
              <Title order={4} variant="link" component="a" href="https://medium.com/toloka/reinforcement-learning-without-reward-engineering-60c63402c59f">Reinforcement Learning without Reward Engineering</Title>
              <Text>
                How to train RL agents without any reward engineering using human judgments.
              </Text>
            </Grid.Col>
          </Grid>
        </Card>

        <Divider my="sm" />

        <Center>
          <Text>Nikita Pavlichenko, {new Date().getFullYear()}</Text>
        </Center>

      </Grid.Col>
      <Grid.Col span={1}></Grid.Col>
    </Grid>
    </div>
  );
}

export default App;
